import styled, { css } from 'styled-components';

import BaseLink from 'Elements/Link';

export const Wrapper = styled.div`
    position: relative;
`;

export const Nav = styled.nav<{ $backgroundColourWhite?: boolean }>`
    ${({ theme, $backgroundColourWhite }) => css`
        position: absolute;
        top: 1.5rem;
        left: 0;
        border-radius: 0 0.25rem 0.25rem 0;
        overflow: hidden;
        z-index: 5;
        padding: 0.25rem;

        @media ${theme.breakpoints.large.media} {
            top: 2.5rem;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: ${$backgroundColourWhite
                ? `${theme.colors.primaryContrast.hex}`
                : `${theme.components.breadcrumbs.background}`};
            opacity: ${$backgroundColourWhite ? 1 : 0.85};
        }
    `}
`;

export const List = styled.ol`
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0.375rem 0.5rem 0.375rem 1.25rem;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        padding: 0.25rem 1.5rem 0.4rem 2.5rem;
    }
`;

export const Item = styled.li`
    font-weight: 300;
    position: relative;
    line-height: 1;
    font-size: 0.875rem;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        font-size: 1.125rem;
    }

    &:not(:first-of-type) {
        padding-left: 0.4rem;
    }

    &:not(:last-of-type) {
        padding-right: 0.4rem;

        &:after {
            content: '/';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
        }
    }

    &:last-of-type {
        font-weight: 700;
    }
`;

export const Link = styled(BaseLink)`
    ${({ theme }) => css`
        color: ${theme.colors.text.hex};
        font-weight: inherit;
        font-size: inherit;
        line-height: 1;
    `}
`;
