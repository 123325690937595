import styled, { css } from 'styled-components';
import { ReactComponent as WaveSVG } from 'Public/graphics/waves/hero-banner/wave-default-hero.svg';
import { ReactComponent as WaveGreySVG } from 'Public/graphics/waves/hero-banner/wave-default-hero-grey.svg';
import { ReactComponent as WavePublicationsSVG } from 'Public/graphics/waves/hero-banner/wave-default-hero-publications.svg';
import { ReactComponent as WaveStoriesSVG } from 'Public/graphics/waves/hero-banner/wave-default-hero-graduate-stories.svg';

export const Container = styled.section<{ $condensed?: boolean; $overflow?: boolean }>`
    ${({ theme, $condensed, $overflow }) => css`
        position: relative;
        min-height: 27.375rem;
        background: var(--hero-bg-colour, ${theme.colors.primary.hex});
        color: var(--hero-txt-colour, ${theme.colors.secondary.hex});
        overflow: ${$overflow ? 'visible' : 'hidden'};
        padding-top: 3rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: column;

        @media ${theme.breakpoints.medium.media} {
            padding-top: 1rem;
            min-height: 19rem;
        }

        @media ${theme.breakpoints.large.media} {
            min-height: 25rem;
        }

        ${$condensed &&
        css`
            min-height: 11.75rem;

            @media ${theme.breakpoints.medium.media} {
                min-height: 15rem;
            }

            @media ${theme.breakpoints.large.media} {
                min-height: 16rem;
            }
        `}
    `}
`;

export const OverflowWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
`;

export const WaveWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible;
`;

export const Wrapper = styled.div`
    flex: 1;
`;

const waveStyle = css`
    position: absolute;
    top: -5rem;
    left: -52.5rem;
    width: 122rem;
    height: 45rem;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        top: -2.25rem;
        left: -7.5rem;
    }
`;

export const Wave = styled(WaveSVG)`
    ${waveStyle}
`;

export const WaveGrey = styled(WaveGreySVG)`
    ${waveStyle}
`;

export const WavePublications = styled(WavePublicationsSVG)`
    ${waveStyle}
`;

export const WaveStories = styled(WaveStoriesSVG)`
    ${waveStyle}
`;
