import React from 'react';

import { Wrapper, Nav, List, Item, Link } from './Breadcrumbs.styled';

export interface Props {
    backgroundColourWhite?: boolean;
    items: {
        id: string;
        label: string;
        href?: string;
    }[];
}

const Breadcrumbs: React.FC<Props> = props => {
    const { items, backgroundColourWhite } = props;

    return (
        <Wrapper>
            <Nav
                aria-label="Breadcrumbs"
                className="c-breadcrumbs"
                $backgroundColourWhite={backgroundColourWhite}
            >
                <List>
                    {items.map((breadcrumb, index) => (
                        <Item key={`breadcrumb-${breadcrumb.id}`}>
                            {breadcrumb.href ? (
                                <Link
                                    label={breadcrumb.label}
                                    href={breadcrumb.href as string}
                                    aria-current={index === items.length - 1 ? 'page' : undefined}
                                    className="c-breadcrumbs-link"
                                    prefetch={false}
                                />
                            ) : (
                                <span
                                    aria-current={index === items.length - 1 ? 'page' : undefined}
                                >
                                    {breadcrumb.label}
                                </span>
                            )}
                        </Item>
                    ))}
                </List>
            </Nav>
        </Wrapper>
    );
};

export default Breadcrumbs;
