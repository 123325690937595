import styled, { css } from 'styled-components';

import ArrowButton from 'Elements/Button/Arrow';

export const Heading = styled.h1`
    ${({ theme }) => css`
        position: relative;
        margin: 4rem 0 0;
        line-height: 1.1;

        @media ${theme.breakpoints.medium.media} {
            margin: 8rem 0 0;
        }

        @media ${theme.breakpoints.large.media} {
            margin: 10rem 0 0;
        }
    `}
`;

const headingSupportCSS = ({ theme }) => css`
    font-family: ${theme.fonts.secondary};
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.2;
    display: block;

    @media ${theme.breakpoints.large.media} {
        font-size: 2rem;
    }
`;

export const HeadingPre = styled.span`
    ${({ theme }) => css`
        ${headingSupportCSS}
        position: absolute;
        top: -1.5rem;

        @media ${theme.breakpoints.large.media} {
            top: -2.25rem;
        }
    `}
`;

export const HeadingMain = styled.span<{ $headingColour?: string }>`
    ${({ $headingColour }) => css`
        ${$headingColour &&
        css`
            color: ${$headingColour};
        `}
    `}
`;

export const HeadingPost = styled.span`
    ${({ theme }) => css`
        ${headingSupportCSS}
        margin: 0.5rem 0 0;

        @media ${theme.breakpoints.large.media} {
            margin: 0;
        }
    `}
`;

export const StyledArrowButton = styled(ArrowButton)`
    margin: 2rem 0 0rem;
`;

export const Text = styled.p`
    max-width: 35rem;
    margin: 2rem 0 0;
    line-height: 1.25;
`;
