import React from 'react';

import { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import {
    Heading,
    HeadingPre,
    HeadingMain,
    HeadingPost,
    StyledArrowButton,
    Text
} from './Inner.styled';

export interface Props {
    heading: string;
    preHeading?: string;
    postHeading?: string;
    text?: string;
    button?: ArrowLinkButtonProps;
    headingColour?: string;
}

const Inner: React.FC<Props> = props => {
    const { heading, preHeading, postHeading, text, button, headingColour } = props;

    return (
        <>
            <Heading>
                {preHeading && <HeadingPre>{preHeading}</HeadingPre>}
                <HeadingMain $headingColour={headingColour}>{heading}</HeadingMain>
                {postHeading && <HeadingPost>{postHeading}</HeadingPost>}
            </Heading>
            {text && <Text>{text}</Text>}
            {button && (
                <StyledArrowButton
                    {...button}
                    variant={button.variant ? button.variant : 'primary'}
                />
            )}
        </>
    );
};

export default Inner;
